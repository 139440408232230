import { useEffect } from 'react';
import { useRouter } from 'next/navigation';
import LoadingAnimationPlat from '@/components/common/LoadingAnimationPlat';
import { ROUTES } from '@/constants/routes';
import EmptyLayout from '@/layouts/empty';
import type { PageWithAuthType, PageWithLayoutType } from '@/types/pages';

const Page: PageWithLayoutType & PageWithAuthType = () => {
  const router = useRouter();

  useEffect(() => {
    router.push(ROUTES.DASHBOARD);
  }, [router]);

  return <LoadingAnimationPlat />;
};

Page.layout = EmptyLayout;
Page.requireAuth = true;

export default Page;
